import React from 'react';
import SystemMonitor from './system-monitor';

function App() {
  return (
    <div className="min-h-screen bg-gray-900 p-4">
      <SystemMonitor />
    </div>
  );
}

export default App;