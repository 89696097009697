import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import {
  PcCase,
  Monitor,
  AppWindow,
  Cpu,
  Users,
  Settings,
  Activity,
  Search,
  X,
  Loader2,
  Menu as MenuIcon, // Añadido el icono del menú
  FileText, // Añadido para el icono de PDF
} from "lucide-react";

interface MenuItemProps {
  text: string;
  selected: boolean;
  onClick: () => void;
  indented?: boolean;
}

interface SystemInfo {
  cpu?: {
    numOfProcessors: number;
    archName: string;
    modelName: string;
    features: string[];
    processors: Array<{
      usage: {
        idle: number;
        kernel: number;
        user: number;
        total: number;
      };
      temperatures: number[];
    }>;
    frequency?: {
      current: number;
      min: number;
      max: number;
    };
    cache?: {
      l1d?: number;
      l1i?: number;
      l2?: number;
      l3?: number;
    };
    flags?: string[];
    temperature?: number;
    core_temps?: number[];
    usage?: number; // Añadimos el campo usage a la estructura existente
  };
  memory?: {
    capacity: number;
    availableCapacity: number;
    usedPercentage: number;
    type?: string; // Añadido el tipo de memoria
    modules?: Array<{
      size: number;
      speed?: number;
      manufacturer?: string;
      location: string;
      memory_type?: string;
      form_factor?: string;
    }>;
  };
  storage?: Array<{
    name: string;
    capacity?: number;  // Ahora es opcional
    available?: number; // Ahora es opcional
    type: string;
    type_name: string;
    drive_letter: string;
  }>;
  display?: {
    bounds: {
      width: number;
      height: number;
    };
    workArea: {
      width: number;
      height: number;
    };
    isPrimary: boolean;
    dpiX: number;
    dpiY: number;
  };
  gpu_info?: Array<{
    model: string;
    memory: number;
    driver_version?: string;
    temperature?: number;
    usage?: number;
  }>;
  network_info?: {
    interfaces: Array<{
      name: string;
      rx_bytes: number;
      tx_bytes: number;
      status: string;
    }>;
    total_rx_bytes: number;
    total_tx_bytes: number;
  };
  peripherals?: {
    input_devices: Array<{
      name: string;
      type: string;
      manufacturer?: string;
      connected: boolean;
      interface: string;
    }>;
    audio_devices: Array<{
      name: string;
      type: "playback" | "recording";
      default: boolean;
      active: boolean;
    }>;
    displays: Array<{
      name: string;
      primary: boolean;
      resolution: {
        width: number;
        height: number;
      };
      refresh_rate?: number;
      connection_type?: string;
    }>;
    printers: Array<{
      name: string;
      status: string;
      is_default: boolean;
      is_shared: boolean;
      location?: string;
      manufacturer?: string;
      model?: string;
      color: boolean;
      duplex: boolean;
    }>;
  };
  os_info?: {
    name: string;
    version: string;
    build: string;
    architecture: string;
    kernel_version?: string;
    uptime: number;
    install_date?: string;
    last_boot?: string;
    registered_owner?: string;
    system_root?: string;
    system_drive?: string;
  };
  processes?: {
    total: number;
    threads: number;
    cpu_intensive: Array<{
      name: string;
      pid: number;
      cpu_usage: number;
      memory_usage: number;
    }>;
    memory_intensive: Array<{
      name: string;
      pid: number;
      cpu_usage: number;
      memory_usage: number;
    }>;
  };
  services?: {
    total: number;
    running: number;
    stopped: number;
  };
  nativeAgent?: {
    version: string | null;
    connected: boolean;
  };
  firmware_info?: {
    is_uefi: boolean;
    secure_boot_enabled?: boolean;
    tpm_version?: string;
    tpm_available: boolean;
    direct_x_version?: string;
  };
}

interface UpdateNotification {
  show: boolean;
  currentVersion: string;
  latestVersion: string;
}

type TabType = "system" | "peripherals" | "os" | "vitals";

const getUsageClass = (usage: number) => usage > 90 ? "text-red-500" : "";
const getBarClass = (usage: number) => usage > 90 ? "bg-red-500" : "bg-blue-500";

const SystemMonitor: React.FC = () => {
  // Añadir estado para el estado de carga inicial
  const [isLoading, setIsLoading] = useState(true);
  const [showExtensionBanner, setShowExtensionBanner] = useState(false);
  const [showRustBanner, setShowRustBanner] = useState(false);
  const [updateNotification, setUpdateNotification] = useState<UpdateNotification>({
    show: false,
    currentVersion: "",
    latestVersion: ""
  });

  // Modificar el estado inicial para que coincida con la vista por defecto
  const [activeTab, setActiveTab] = useState<TabType>("system");
  const [selectedMenuItem, setSelectedMenuItem] = useState("summary");
  const [cpuData, setCpuData] = useState<number[][]>(Array(8).fill([]));
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notifications, setNotifications] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortConfig, setSortConfig] = useState({
    key: "cpu",
    direction: "desc",
  });
  // Reemplazar showNotifications por showMenu
  const [showMenu, setShowMenu] = useState(false);
  const [systemInfo, setSystemInfo] = useState<SystemInfo>({});
  // Agregar un ref para el menú para cerrar al hacer click fuera
  const menuRef = useRef<HTMLDivElement>(null);
  // Agregar un ref para el botón del menú
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const systemDrive = systemInfo.os_info?.system_drive;
  const isWindows = systemInfo.os_info?.name?.toLowerCase().includes('windows');
  const systemDisk = systemInfo.storage?.find(d => 
    (isWindows && systemDrive && d.drive_letter === systemDrive?.replace(/:$/, "")) || 
    (!isWindows && systemInfo.os_info && d.name === "/")
  );
  const systemDiskUsage = systemDisk?.capacity && systemDisk?.available && systemDisk.capacity > 0
    ? ((systemDisk.capacity - systemDisk.available) / systemDisk.capacity) * 100
    : 0;

  // Efecto para datos del sistema
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "SYSTEM_INFO_RESPONSE") {
        if (event.data.data.error) {
          console.warn("Extension error:", event.data.data.error);
          return;
        }
        console.log("Received system info");
        setSystemInfo(event.data.data);

        // Comprobar si hay actualización disponible
        if (event.data.data.nativeAgent?.version && event.data.data.nativeAgent.latestVersion) {
          const current = event.data.data.nativeAgent.version;
          const latest = event.data.data.nativeAgent.latestVersion;
          if (current !== latest) {
            setUpdateNotification({
              show: true,
              currentVersion: current,
              latestVersion: latest
            });
          }
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Solicitar información inicial
    window.postMessage({ type: "REQUEST_SYSTEM_INFO" }, "*");

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []); // Solo se ejecuta una vez al montar el componente

  // Efecto para comprobar si la extensión está instalada
  useEffect(() => {
    const checkExtensionAndRust = () => {
      let extensionResponded = false;
      let rustResponded = false;
      let timeoutId: NodeJS.Timeout;

      const handleResponse = (event: MessageEvent) => {
        if (event.data.type === "SYSTEM_INFO_RESPONSE") {
          extensionResponded = true;
          setIsLoading(false);
          clearTimeout(timeoutId);

          // Si la extensión responde, no mostrar el banner de la extensión
          setShowExtensionBanner(false);

          // Verificar si recibimos datos del programa Rust
          if (
            event.data.data &&
            (event.data.data.cpu_info ||
              event.data.data.gpu_info ||
              event.data.data.network_info)
          ) {
            rustResponded = true;
            setShowRustBanner(false);
          } else {
            setShowRustBanner(true);
          }
        }
      };

      // Configurar un timeout para mostrar los banners si no hay respuesta
      timeoutId = setTimeout(() => {
        if (!extensionResponded) {
          setShowExtensionBanner(true);
          setShowRustBanner(true);
        }
        setIsLoading(false);
      }, 2000); // Esperar 2 segundos antes de mostrar los banners

      window.addEventListener("message", handleResponse);
      window.postMessage({ type: "REQUEST_SYSTEM_INFO" }, "*");

      return () => {
        window.removeEventListener("message", handleResponse);
        clearTimeout(timeoutId);
      };
    };

    checkExtensionAndRust();
    const interval = setInterval(checkExtensionAndRust, 5000);

    return () => clearInterval(interval);
  }, []);

  // Modificar la función que maneja los clicks del menú lateral
  const handleMenuItemClick = (menuItem: string) => {
    // console.log('Menu item clicked:', menuItem); // Quitar depuración
    setSelectedMenuItem(menuItem);
    setActiveTab("system");
  };

  // Modificar la función para manejar los clicks del menú superior
  const handleTabClick = (tab: TabType) => {
    setActiveTab(tab);

    if (tab === "system") {
      // Al volver a system, si no hay selección, poner summary
      if (!selectedMenuItem) {
        setSelectedMenuItem("summary");
      }
    }
  };

  // Modificar la función toggleNotifications a toggleMenu
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Agregar una función para generar PDF
  const generatePDF = () => {
    // Implementar la lógica para generar el PDF
    alert('Option not available yet');
    // Aquí iría la lógica para generar el PDF
    setShowMenu(false); // Cerrar el menú al seleccionar
  };

  // Añadir un efecto para cerrar el menú al hacer clic fuera
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const MenuItem: React.FC<MenuItemProps> = ({
    text,
    selected,
    onClick,
    indented = false,
  }) => (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          onClick();
        }
      }}
      className={`
        cursor-pointer 
        py-1 
        ${indented ? "pl-8" : "pl-4"} 
        ${selected ? "bg-gray-600" : "hover:bg-gray-600"}
        transition-colors
        duration-150
        hover:ring-1
        hover:ring-blue-500
        hover:ring-offset-0
        focus:ring-0
      `}
    >
      <span className="text-sm">{text}</span>
    </div>
  );

  // Reemplazar NotificationsPanel por MenuPanel
  const MenuPanel = () => {
    // Obtener las dimensiones y posición del botón
    const buttonRect = menuButtonRef.current?.getBoundingClientRect();
    
    return (
      <div 
        ref={menuRef} 
        className="absolute z-50 bg-gray-700 rounded shadow-lg overflow-hidden w-56" 
        style={{
          top: buttonRect ? buttonRect.bottom + window.scrollY : '16px',
          left: buttonRect ? 
                (buttonRect.left + (buttonRect.width / 2) - 56) + window.scrollX : 'auto',
          transform: 'translateX(-50%)',
          right: 'auto',
        }}
      >
        <div className="p-2">
          <button 
            onClick={generatePDF} 
            className="w-full text-left px-3 py-2 hover:bg-gray-600 rounded flex items-center gap-2 transition-colors"
          >
            <FileText className="w-5 h-5" />
            <span>Generate PDF report</span>
          </button>
        </div>
      </div>
    );
  };

  const TabContent = () => {
    // CPU Information render function
    const renderCPUInfo = () => (
      <div className="bg-gray-700 p-4 rounded">
        <h2 className="text-xl mb-4">CPU Information</h2>
        <div className="bg-gray-800 p-4 rounded">
          <div className="text-sm space-y-3">
            <div>
              Model:{" "}
              {systemInfo.cpu?.modelName || (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            <div>
              Architecture:{" "}
              {systemInfo.cpu?.archName || (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            <div>
              Physical cores:{" "}
              {systemInfo.cpu?.numOfProcessors || (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            <div>
              Base frequency:{" "}
              {systemInfo.cpu?.frequency?.current ? (
                `${systemInfo.cpu.frequency.current.toFixed(2)} MHz`
              ) : (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            <div>
              Max frequency:{" "}
              {systemInfo.cpu?.frequency?.max ? (
                `${systemInfo.cpu.frequency.max.toFixed(2)} MHz`
              ) : (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            <div>
              L1 Cache:{" "}
              {systemInfo.cpu?.cache?.l1d ? (
                `${systemInfo.cpu.cache.l1d} KB (Data) / ${
                  systemInfo.cpu.cache.l1i || 0
                } KB (Instruction)`
              ) : (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            <div>
              L2 Cache:{" "}
              {systemInfo.cpu?.cache?.l2 ? (
                `${systemInfo.cpu.cache.l2} KB`
              ) : (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            <div>
              L3 Cache:{" "}
              {systemInfo.cpu?.cache?.l3 ? (
                `${systemInfo.cpu.cache.l3} KB`
              ) : (
                <span className="text-red-500">Not available</span>
              )}
            </div>
            {/* 
            <div>
              Temperature: {systemInfo.cpu?.temperature ? 
                `${systemInfo.cpu.temperature.toFixed(1)}°C` : 
                <span className="text-red-500">Not available</span>}
            </div>
            
            {systemInfo.cpu?.core_temps && systemInfo.cpu.core_temps.length > 0 && (
              <div className="mt-2">
                <div className="font-medium mb-1">Core Temperatures:</div>
                <div className="grid grid-cols-4 gap-2">
                  {systemInfo.cpu.core_temps.map((temp, i) => (
                    <div key={i} className="bg-gray-700 p-2 rounded">
                      Core {i}: {temp.toFixed(1)}°C
                    </div>
                  ))}
                </div>
              </div>
            )}
            */}
            {systemInfo.cpu?.flags && (
              <div className="mt-2">
                <div className="font-medium mb-1">CPU Features:</div>
                <div className="flex flex-wrap gap-1">
                  {systemInfo.cpu.flags.map((flag, i) => (
                    <span
                      key={i}
                      className="bg-gray-700 px-2 py-1 rounded text-xs"
                    >
                      {flag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );

    // Memory Information render function
    const renderMemoryInfo = () => (
      <div className="bg-gray-700 p-4 rounded">
        <h2 className="text-xl mb-4">Memory Information</h2>
        <div className="space-y-4">
          <div className="bg-gray-800 p-4 rounded">
            <div className="text-sm space-y-3">
              <div>
                RAM:{" "}
                {systemInfo.memory ? (
                  `${Math.round(
                    systemInfo.memory.capacity / (1024 * 1024 * 1024)
                  )} GB`
                ) : (
                  <span className="text-red-500">Not available</span>
                )}
              </div>
              {/*<div>
                Available memory: {systemInfo.memory ? 
                  `${Math.round(systemInfo.memory.availableCapacity / (1024 * 1024 * 1024))} GB` : 
                  <span className="text-red-500">Not available</span>}
              </div>
              <div>
                In use: {systemInfo.memory ? 
                  `${Math.round((systemInfo.memory.capacity - systemInfo.memory.availableCapacity) / (1024 * 1024 * 1024))} GB` : 
                  <span className="text-red-500">Not available</span>}
              </div>*/}
              <div>
                Memory type:{" "}
                {systemInfo.memory?.type || (
                  <span className="text-red-500">Not available</span>
                )}
              </div>
            </div>
          </div>

          {/* Módulos de memoria */}
          {systemInfo.memory?.modules &&
            systemInfo.memory.modules.length > 0 && (
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-3">Memory Modules</h3>
                <div className="space-y-4">
                  {systemInfo.memory.modules.map((module, index) => (
                    <div
                      key={index}
                      className="text-sm space-y-2 border-l-2 border-blue-500 pl-3"
                    >
                      <div className="font-medium">
                        Module {index + 1} - {module.location}
                      </div>
                      <div>
                        Size: {Math.round(module.size / (1024 * 1024 * 1024))}{" "}
                        GB
                      </div>
                      {module.speed && <div>Speed: {module.speed} MHz</div>}
                      {module.manufacturer && (
                        <div>Manufacturer: {module.manufacturer}</div>
                      )}
                      {module.memory_type && (
                        <div>Type: {module.memory_type}</div>
                      )}
                      {module.form_factor && (
                        <div>Form Factor: {module.form_factor}</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>
    );

    // Network Information render function
    const renderNetworkInfo = () => (
      <div className="bg-gray-700 p-4 rounded">
        <h2 className="text-xl mb-4">Network Information</h2>
        <div className="space-y-4">
          {systemInfo.network_info ? (
            <>
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">Total Traffic</h3>
                <div className="text-sm space-y-2">
                  <div>
                    Total Received:{" "}
                    {formatBytes(systemInfo.network_info.total_rx_bytes)}
                  </div>
                  <div>
                    Total Sent:{" "}
                    {formatBytes(systemInfo.network_info.total_tx_bytes)}
                  </div>
                </div>
              </div>

              {systemInfo.network_info.interfaces.map((iface, index) => (
                <div key={index} className="bg-gray-800 p-4 rounded">
                  <h3 className="font-semibold mb-2">{iface.name}</h3>
                  <div className="text-sm space-y-2">
                    <div>Status: {iface.status}</div>
                    <div>Received: {formatBytes(iface.rx_bytes)}</div>
                    <div>Sent: {formatBytes(iface.tx_bytes)}</div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="bg-gray-800 p-4 rounded">
              <span className="text-red-500">
                No network information available
              </span>
            </div>
          )}
        </div>
      </div>
    );

    // Disk Information render function
    const renderDiskInfo = () => (
      <div className="bg-gray-700 p-4 rounded">
        <h2 className="text-xl mb-4">Storage Information</h2>
        <div className="space-y-4">
          {systemInfo.storage ? (
            systemInfo.storage.map((disk, index) => (
              <div key={index} className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">
                  {disk.name}
                  {disk.drive_letter && ` (${disk.drive_letter}:)`}
                  {(isWindows && disk.drive_letter === systemDrive?.replace(':', '')) || 
                  (!isWindows && disk.name === "/") ? (
                    <span className="text-blue-400 ml-2">(System)</span>
                  ) : null}
                </h3>
                <div className="text-sm space-y-3">
                  <div>
                    Type:{" "}
                    {disk.type_name || disk.type || (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  <div>
                    Total capacity:{" "}
                    {disk.capacity ? (
                      `${Math.round(disk.capacity / (1024 * 1024 * 1024))} GB`
                    ) : (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  <div>
                    Free space:{" "}
                    {disk.available ? (
                      `${Math.round(disk.available / (1024 * 1024 * 1024))} GB`
                    ) : (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  <div>
                    Used space:{" "}
                    {disk.capacity && disk.available ? (
                      `${Math.round(
                        (disk.capacity - disk.available) / (1024 * 1024 * 1024)
                      )} GB`
                    ) : (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  <div>
                    Used percentage:{" "}
                    {disk.capacity && disk.available ? (
                      <span>
                        <span  className={getUsageClass(((disk.capacity - disk.available) / disk.capacity) * 100)}>{Math.round(
                          ((disk.capacity - disk.available) / disk.capacity) * 10000
                        ) /100}</span>%
                      </span>
                    ) : (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  <div className="w-full bg-gray-700 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className={`${getBarClass(
                        disk.capacity && disk.available 
                          ? ((disk.capacity - disk.available) / disk.capacity) * 100 
                          : 0
                      )} h-2.5 rounded-full`}
                      style={{
                        width: disk.capacity && disk.available
                          ? `${Math.round(((disk.capacity - disk.available) / disk.capacity) * 100)}%`
                          : "0%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="bg-gray-800 p-4 rounded">
              <span className="text-red-500">
                No storage information available
              </span>
            </div>
          )}
        </div>
      </div>
    );

    // GPU Information render function
    const renderGPUInfo = () => (
      <div className="bg-gray-700 p-4 rounded">
        <h2 className="text-xl mb-4">GPU Information</h2>
        <div className="space-y-4">
          {systemInfo.gpu_info && systemInfo.gpu_info.length > 0 ? (
            systemInfo.gpu_info.map((gpu, index) => (
              <div key={index} className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">GPU {index + 1}</h3>
                <div className="text-sm space-y-3">
                  <div>
                    Model:{" "}
                    {gpu.model || (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  <div>
                    Dedicated memory:{" "}
                    {gpu.memory ? (
                      `${Math.round(gpu.memory / (1024 * 1024))} MB`
                    ) : (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  <div>
                    Driver version:{" "}
                    {gpu.driver_version || (
                      <span className="text-red-500">Not available</span>
                    )}
                  </div>
                  {/*<div>
                    Temperature: {gpu.temperature ? 
                      `${gpu.temperature.toFixed(1)}°C` : 
                      <span className="text-red-500">Not available</span>}
                  </div>
                  <div>
                    Usage: {gpu.usage ? 
                      `${gpu.usage.toFixed(1)}%` : 
                      <span className="text-red-500">Not available</span>}
                  </div>
                  */}
                </div>
              </div>
            ))
          ) : (
            <div className="bg-gray-800 p-4 rounded">
              <span className="text-red-500">No GPU information available</span>
            </div>
          )}
        </div>
      </div>
    );

    const renderOSInfo = () => (
      <div className="bg-gray-700 p-4 rounded">
        <h2 className="text-xl mb-4">Operating System Information</h2>
        <div className="space-y-4">
          <div className="bg-gray-800 p-4 rounded">
            <div className="text-sm space-y-3">
              <div>
                OS Name:{" "}
                {systemInfo.os_info?.name || (
                  <span className="text-red-500">Not available</span>
                )}
              </div>
              <div>
                Version:{" "}
                {systemInfo.os_info?.version || (
                  <span className="text-red-500">Not available</span>
                )}
              </div>
              <div>
                Build:{" "}
                {systemInfo.os_info?.build || (
                  <span className="text-red-500">Not available</span>
                )}
              </div>
              <div>
                Architecture:{" "}
                {systemInfo.os_info?.architecture || (
                  <span className="text-red-500">Not available</span>
                )}
              </div>
              {systemInfo.os_info?.kernel_version && (
                <div>Kernel Version: {systemInfo.os_info.kernel_version}</div>
              )}
            </div>
          </div>

          <div className="bg-gray-800 p-4 rounded">
            <h3 className="font-semibold mb-2">System Details</h3>
            <div className="text-sm space-y-3">
              <div>
                System Drive:{" "}
                {systemInfo.os_info?.system_drive || (
                  <span className="text-red-500">Not available</span>
                )}
              </div>
              {/*<div>
                System Root:{" "}
                {systemInfo.os_info?.system_root || (
                  <span className="text-red-500">Not available</span>
                )}
              </div>*/}
              {systemInfo.os_info?.registered_owner && (
                <div>Registered Owner: {systemInfo.os_info.registered_owner}</div>
              )}
            </div>
          </div>

          {/* Añadir sección para firmware e información de compatibilidad */}
          {systemInfo.firmware_info && (
            <div className="bg-gray-800 p-4 rounded">
              <h3 className="font-semibold mb-2">Firmware & Security</h3>
              <div className="text-sm space-y-3">
                <div>
                  Boot Type: {systemInfo.firmware_info.is_uefi ? "UEFI" : "Legacy BIOS"}
                </div>
                {systemInfo.firmware_info.secure_boot_enabled !== undefined && (
                  <div>
                    Secure Boot: {systemInfo.firmware_info.secure_boot_enabled ? "Enabled" : "Disabled"}
                  </div>
                )}
                { /* Need admin rights to check TPM status
                <div>
                  TPM: {systemInfo.firmware_info.tpm_available ? 
                    (systemInfo.firmware_info.tpm_version ? 
                      `Available (${systemInfo.firmware_info.tpm_version})` : 
                      "Available") : 
                    "Not detected"}
                </div>
              */ }
              </div>
            </div>
          )}

          <div className="bg-gray-800 p-4 rounded">
            <h3 className="font-semibold mb-2">Time Information</h3>
            <div className="text-sm space-y-3">
              {systemInfo.os_info?.last_boot && (
                <div>Last Boot: {formatDate(systemInfo.os_info.last_boot)}</div>
              )}
              {systemInfo.os_info?.install_date && (
                <div>Install Date: {formatDate(systemInfo.os_info.install_date)}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );

    // Modificar el switch para manejar los menús laterales
    switch (activeTab) {
      case "system":
        if (selectedMenuItem === "cpu") return renderCPUInfo();
        if (selectedMenuItem === "memory") return renderMemoryInfo();
        if (selectedMenuItem === "disk") return renderDiskInfo();
        if (selectedMenuItem === "network") return renderNetworkInfo();
        if (selectedMenuItem === "gpu") return renderGPUInfo();
        // if (selectedMenuItem === 'sensors') return renderSensorsInfo();
        // ... resto del caso 'system' para el resumen general ...
        return (
          <div className="bg-gray-700 p-4 rounded">
            <h2 className="text-xl mb-4">System Information</h2>
            <div className="space-y-4">
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">CPU</h3>
                <div className="text-sm space-y-2">
                  <div>
                    Model:{" "}
                    {!isLoading ? (
                      systemInfo.cpu?.modelName || (
                        <span className="text-red-500">Not available</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    Architecture:{" "}
                    {!isLoading ? (
                      systemInfo.cpu?.archName || (
                        <span className="text-red-500">Not available</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    Cores:{" "}
                    {!isLoading ? (
                      systemInfo.cpu?.numOfProcessors || (
                        <span className="text-red-500">Not available</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">Memory</h3>
                <div className="text-sm space-y-2">
                  <div>
                    RAM:{" "}
                    {!isLoading ? (
                      systemInfo.memory ? (
                        `${Math.round(
                          systemInfo.memory.capacity / (1024 * 1024 * 1024)
                        )} GB`
                      ) : (
                        <span className="text-red-500">Not available</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    Memory type:{" "}
                    {!isLoading ? (
                      systemInfo.memory?.type || (
                        <span className="text-red-500">Not available</span>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {/* GPU Summary */}
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">GPUs</h3>
                <div className="text-sm space-y-2">
                  {!isLoading ? (
                    systemInfo.gpu_info && systemInfo.gpu_info.length > 0 ? (
                      systemInfo.gpu_info.map((gpu, index) => (
                        <div key={index} className="space-y-1">
                          <div>
                            GPU {index + 1}:{" "}
                            {gpu.model || (
                              <span className="text-red-500">Not available</span>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <span className="text-red-500">
                        No GPU information available
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* Storage Summary */}
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">Storage</h3>
                <div className="text-sm space-y-2">
                  {!isLoading ? (
                    systemInfo.storage ? (
                      systemInfo.storage.map((disk, index) => (
                        <div key={index} className="space-y-1">
                          <div className="flex justify-between items-center">
                            <span className="truncate">
                              {disk.name}
                              {disk.drive_letter && ` (${disk.drive_letter}:)`}
                              {(isWindows && disk.drive_letter === systemDrive?.replace(':', '')) || 
                              (!isWindows && disk.name === "/") ? (
                                <span className="text-blue-400 ml-1">[System]</span>
                              ) : null}
                            </span>
                            <span className="text-gray-400 ml-2">
                              {disk.capacity 
                                ? `${Math.round(disk.capacity / (1024 * 1024 * 1024))} GB`
                                : 'N/A'}
                            </span>
                          </div>
                          <div className="w-full bg-gray-700 rounded-full h-1.5">
                            <div
                              className={`${getBarClass(
                                disk.capacity && disk.available 
                                  ? ((disk.capacity - disk.available) / disk.capacity) * 100 
                                  : 0
                              )} h-1.5 rounded-full`}
                              style={{
                                width: disk.capacity && disk.available
                                  ? `${Math.round(((disk.capacity - disk.available) / disk.capacity) * 100)}%`
                                  : "0%",
                              }}
                            ></div>
                          </div>
                          <div className="text-xs text-gray-400">
                            {disk.available
                              ? `${Math.round(
                                  disk.available / (1024 * 1024 * 1024)
                                )} GB free`
                              : ""}
                          </div>
                        </div>
                      ))
                    ) : (
                      <span className="text-red-500">
                        No storage information available
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case "peripherals":
        return (
          <div className="bg-gray-700 p-4 rounded">
            <h2 className="text-xl mb-4">Peripherals</h2>
            <div className="space-y-4">
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">Input Devices</h3>
                <div className="text-sm space-y-2">
                  {systemInfo.peripherals?.input_devices ? (
                    systemInfo.peripherals.input_devices.map(
                      (device, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <div>
                            <span className="text-gray-400">
                              {device.type}:{" "}
                            </span>
                            {device.name}
                            {device.manufacturer && (
                              <span className="text-gray-400 ml-2">
                                ({device.manufacturer})
                              </span>
                            )}
                          </div>
                          <span
                            className={`px-2 py-1 rounded ${
                              device.connected ? "bg-green-600" : "bg-gray-600"
                            }`}
                          >
                            {device.connected ? "Connected" : "Disconnected"}
                          </span>
                        </div>
                      )
                    )
                  ) : (
                    <span className="text-red-500">
                      No input devices available
                    </span>
                  )}
                </div>
              </div>

              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">Audio Devices</h3>
                <div className="text-sm space-y-2">
                  {systemInfo.peripherals?.audio_devices ? (
                    <>
                      <div className="mb-3">
                        <div className="font-medium text-gray-400 mb-2">
                          Playback Devices
                        </div>
                        {systemInfo.peripherals.audio_devices
                          .filter((device) => device.type === "playback")
                          .map((device, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center mb-1"
                            >
                              <div>
                                {device.name}
                                {device.default && (
                                  <span className="text-green-500 ml-2">
                                    (Default)
                                  </span>
                                )}
                              </div>
                              <span
                                className={`px-2 py-1 rounded ${
                                  device.active ? "bg-green-600" : "bg-gray-600"
                                }`}
                              >
                                {device.active ? "Active" : "Inactive"}
                              </span>
                            </div>
                          ))}
                      </div>
                      <div>
                        <div className="font-medium text-gray-400 mb-2">
                          Recording Devices
                        </div>
                        {systemInfo.peripherals.audio_devices
                          .filter((device) => device.type === "recording")
                          .map((device, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center mb-1"
                            >
                              <div>
                                {device.name}
                                {device.default && (
                                  <span className="text-green-500 ml-2">
                                    (Default)
                                  </span>
                                )}
                              </div>
                              <span
                                className={`px-2 py-1 rounded ${
                                  device.active ? "bg-green-600" : "bg-gray-600"
                                }`}
                              >
                                {device.active ? "Active" : "Inactive"}
                              </span>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <span className="text-red-500">
                      No audio devices available
                    </span>
                  )}
                </div>
              </div>

              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">Display Devices</h3>
                <div className="text-sm space-y-2">
                  {systemInfo.peripherals?.displays ? (
                    systemInfo.peripherals.displays.map((display, index) => (
                      <div key={index} className="space-y-1">
                        <div className="flex justify-between items-center">
                          <div>
                            {display.name}
                            {display.primary && (
                              <span className="text-blue-500 ml-2">
                                (Primary)
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-gray-400">
                          {display.resolution.width} x{" "}
                          {display.resolution.height}
                          {display.refresh_rate &&
                            ` @ ${display.refresh_rate}Hz`}
                        </div>
                        {display.connection_type && (
                          <div className="text-gray-400">
                            Connection: {display.connection_type}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <span className="text-red-500">
                      No display devices available
                    </span>
                  )}
                </div>
              </div>

              <div className="bg-gray-800 p-4 rounded">
                <h3 className="font-semibold mb-2">Printers</h3>
                <div className="text-sm space-y-2">
                  {systemInfo.peripherals?.printers ? (
                    systemInfo.peripherals.printers.map((printer, index) => (
                      <div key={index} className="bg-gray-700 p-3 rounded">
                        <div className="flex justify-between items-start">
                          <div>
                            <div className="font-medium flex items-center gap-2">
                              {printer.name}
                              {printer.is_default && (
                                <span className="text-green-500 text-xs">
                                  (Default)
                                </span>
                              )}
                            </div>
                            {printer.manufacturer && (
                              <div className="text-gray-400 text-xs">
                                {printer.manufacturer}
                                {printer.model && ` - ${printer.model}`}
                              </div>
                            )}
                          </div>
                          <div
                            className={`px-2 py-1 rounded text-xs ${
                              printer.status.toLowerCase() === "ready"
                                ? "bg-green-600"
                                : "bg-yellow-600"
                            }`}
                          >
                            {printer.status}
                          </div>
                        </div>
                        <div className="mt-2 flex flex-wrap gap-2">
                          {printer.color && (
                            <span className="text-xs bg-gray-600 px-2 py-1 rounded">
                              Color
                            </span>
                          )}
                          {printer.duplex && (
                            <span className="text-xs bg-gray-600 px-2 py-1 rounded">
                              Duplex
                            </span>
                          )}
                          {printer.is_shared && (
                            <span className="text-xs bg-gray-600 px-2 py-1 rounded">
                              Shared
                            </span>
                          )}
                          {printer.location && (
                            <span className="text-xs bg-gray-600 px-2 py-1 rounded">
                              {printer.location}
                            </span>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <span className="text-red-500">No printers available</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case "os":
        return renderOSInfo();
      case "vitals":
        return (
          <div className="bg-gray-700 p-4 rounded space-y-4">
            <h2 className="text-xl mb-4">System Vitals</h2>
            
            {/* CPU Usage */}
            <div className="bg-gray-800 p-4 rounded">
              <div className="flex justify-between mb-2">
                <span>CPU Usage</span>
                <span>
                  {(systemInfo.cpu?.usage ?? 0).toFixed(1)}%
                </span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2.5">
                <div
                  className="bg-green-600 h-2.5 rounded-full transition-all duration-500"
                  style={{ width: `${systemInfo.cpu?.usage ?? 0}%` }}
                ></div>
              </div>
            </div>
      
            {/* Memory Usage */}
            <div className="bg-gray-800 p-4 rounded">
              <div className="flex justify-between mb-2">
                <span>Memory Usage</span>
                <span>
                  {systemInfo.memory ? 
                    `${((systemInfo.memory.capacity - systemInfo.memory.availableCapacity) / (1024 * 1024 * 1024)).toFixed(1)} GB / 
                     ${(systemInfo.memory.capacity / (1024 * 1024 * 1024)).toFixed(1)} GB 
                     (${systemInfo.memory.usedPercentage.toFixed(1)}%)` : 
                    'N/A'}
                </span>
              </div>
              <div className="w-full bg-gray-700 rounded-full h-2.5">
                <div
                  className={`${getBarClass(systemInfo.memory?.usedPercentage ?? 0)} h-2.5 rounded-full transition-all duration-500`}
                  style={{ width: `${systemInfo.memory?.usedPercentage || 0}%` }}
                ></div>
              </div>
            </div>
      
            {/* System Stats */}
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="text-lg mb-2">System Load</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Running Processes:</span>
                    <span>{systemInfo.processes?.total || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Active Services:</span>
                    <span>{systemInfo.services?.running || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>System Uptime:</span>
                    <span>{systemInfo.os_info ? formatUptime(systemInfo.os_info.uptime) : 'N/A'}</span>
                  </div>
                </div>
              </div>
      
              <div className="bg-gray-800 p-4 rounded">
                <h3 className="text-lg mb-2">Network Activity</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Total Received:</span>
                    <span>{systemInfo.network_info ? formatBytes(systemInfo.network_info.total_rx_bytes) : 'N/A'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Total Sent:</span>
                    <span>{systemInfo.network_info ? formatBytes(systemInfo.network_info.total_tx_bytes) : 'N/A'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Función auxiliar para formatear bytes
  const formatBytes = (bytes: number): string => {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
  };

  // Función auxiliar para formatear el tiempo de uptime
  const formatUptime = (seconds?: number): string => {
    if (!seconds) return "Not available";
    
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    
    const parts = [];
    if (days > 0) parts.push(`${days} day${days !== 1 ? 's' : ''}`);
    if (hours > 0) parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
    if (minutes > 0) parts.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
    
    return parts.length > 0 ? parts.join(', ') : "Just started";
  };

  // Función auxiliar para formatear fechas de WMI
  const formatDate = (dateString: string): string => {
    // El formato es: YYYYMMDDHHMMSS.mmmmmm±UUU
    // donde UUU es el offset en minutos
    const year = parseInt(dateString.substring(0, 4));
    const month = parseInt(dateString.substring(4, 6)) - 1; // Los meses en JS van de 0-11
    const day = parseInt(dateString.substring(6, 8));
    const hour = parseInt(dateString.substring(8, 10));
    const minute = parseInt(dateString.substring(10, 12));
    const second = parseInt(dateString.substring(12, 14));
    
    const offsetSign = dateString.charAt(21);
    const offsetMinutes = parseInt(dateString.substring(22, 25));
    
    // Crear fecha en UTC
    const date = new Date(Date.UTC(year, month, day, hour, minute, second));
    
    // Ajustar por el offset
    const offsetMs = offsetMinutes * 60 * 1000 * (offsetSign === '+' ? 1 : -1);
    date.setTime(date.getTime() + offsetMs);

    // Formatear la fecha para mostrar
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const getInstallerLink = () => {
    const platform = window.navigator.platform.toLowerCase();
    if (platform.includes('win')) {
      return '/downloads/pchardware-monitor-installer.exe';
    } else if (platform.includes('linux')) {
      return '/downloads/pchardware-monitor-installer';
    } else if (platform.includes('mac')) {
      return '/downloads/pchardware-monitor-installer.dmg';
    }
    return '/downloads/pchardware-monitor-installer'; // fallback a linux
  };
  
  const getInstallerText = () => {
    const platform = window.navigator.platform.toLowerCase();
    if (platform.includes('win')) {
      return 'Download Windows Installer';
    } else if (platform.includes('linux')) {
      return 'Download Linux Installer';
    } else if (platform.includes('mac')) {
      return 'Download macOS Installer';
    }
    return 'Download Installer';
  };

  // Banner modificado para mostrar ambos siempre al inicio
  const ExtensionBanner = () => {
    const banners = [];

    // Siempre mostramos el banner de la extensión si no está instalada
    if (showExtensionBanner) {
      banners.push(
        <div
          key="extension"
          className="fixed top-0 left-0 right-0 z-50 bg-blue-600 text-white px-4 py-1 flex items-center justify-between"
        >
          <div className="flex-1">
            <span>
              💡 To view your system basic details, you'll need a Google Chrome
              extension.
            </span>
          </div>
          <div className="flex items-center gap-4">
            <a
              href="https://chrome.google.com/webstore/detail/tu-extension-id"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-blue-600 px-4 py-1 rounded-md text-sm font-medium hover:bg-blue-50 transition-colors"
            >
              Install Extension
            </a>
            <button
              onClick={() => setShowExtensionBanner(false)}
              className="text-white hover:text-blue-100 transition-colors"
              aria-label="Close banner"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
      );
    }

    // Siempre mostramos el banner del programa Rust
    if (showRustBanner) {
      banners.push(
        <div
          key="rust"
          className="fixed top-0 left-0 right-0 z-40 bg-amber-600 text-white px-4 py-1 flex items-center justify-between"
          style={{ marginTop: showExtensionBanner ? "36px" : "0px" }}
        >
          <div className="flex-1">
            <span>
              🔧 For more extended info you'll need to install also a helper for
              the extension.
            </span>
          </div>
          <div className="flex items-center gap-4">
            <a
              href={getInstallerLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-amber-600 px-4 py-1 rounded-md text-sm font-medium hover:bg-amber-50 transition-colors"
            >
              {getInstallerText()}
            </a>
            <button
              onClick={() => setShowRustBanner(false)}
              className="text-white hover:text-amber-100 transition-colors"
              aria-label="Close banner"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
      );
    }

    return createPortal(<>{banners}</>, document.body);
  };

  const UpdateBanner = () => {
    if (!updateNotification.show) return null;

    return (
      <div className="fixed top-0 left-0 right-0 z-60 bg-yellow-600 text-white px-4 py-1 flex items-center justify-between">
        <div className="flex-1">
          <span>
            🔄 A new version ({updateNotification.latestVersion}) of the Agent is available. 
            Your current version is {updateNotification.currentVersion}.
          </span>
        </div>
        <div className="flex items-center gap-4">
          <a
            href={getInstallerLink()}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-yellow-600 px-4 py-1 rounded-md text-sm font-medium hover:bg-yellow-50 transition-colors"
          >
            Download Update
          </a>
          <button
            onClick={() => setUpdateNotification(prev => ({ ...prev, show: false }))}
            className="text-white hover:text-yellow-100 transition-colors"
            aria-label="Close banner"
          >
              <X className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {!isLoading && (
        <>
          <UpdateBanner />
          <ExtensionBanner />
        </>
      )}
      
      {/* Contenedor principal siempre visible */}
      <div className={`container mx-auto px-4 py-8 ${
        (showExtensionBanner && showRustBanner && updateNotification.show)
          ? "pt-36"
          : (showExtensionBanner && showRustBanner) || (showExtensionBanner && updateNotification.show) || (showRustBanner && updateNotification.show)
          ? "pt-28"
          : showExtensionBanner || showRustBanner || updateNotification.show
          ? "pt-20"
          : ""
      }`}>
        <div className="bg-gray-800 text-white p-4 rounded-lg w-full max-w-4xl mx-auto">
          {/* Header con menú */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 bg-green-500 rounded"></div>
                <span>CPU:</span>
                <span>{(systemInfo.cpu?.usage ?? 0).toFixed(1)}%</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 bg-blue-500 rounded"></div>
                <span>RAM:</span>
                <span className={getUsageClass(systemInfo.memory?.usedPercentage ?? 0)}>
                  {systemInfo.memory?.usedPercentage.toFixed(1) ?? '0.0'}%
                </span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 bg-purple-500 rounded"></div>
                <span>System Disk:</span>
                <span className={getUsageClass(systemDiskUsage)}>
                  {systemDiskUsage.toFixed(1)}%
                </span>
              </div>
            </div>
            <div>
              <div className="text-lg font-semibold">my.PCHardware.org</div>
              {systemInfo.nativeAgent && (
                <div className="text-xs text-gray-400 text-right">
                  Agent v{systemInfo.nativeAgent.version || 'Not available'} | App v1.0.0
                </div>
              )}
            </div>
            <div className="flex items-center gap-4">
              <button 
                className="relative" 
                onClick={toggleMenu}
                ref={menuButtonRef} // Añadimos la referencia aquí
              >
                <MenuIcon className="w-6 h-6" />
              </button>
            </div>
          </div>

          {/* Overlay de carga */}
          {isLoading && (
            <div className="absolute inset-0 bg-gray-900/50 flex flex-col items-center justify-center z-50">
              <Loader2 className="w-16 h-16 animate-spin text-blue-500" />
              <p className="text-gray-400 mt-4 text-lg">Loading system information...</p>
            </div>
          )}

          {/* Show menu panel when showMenu is true */}
          {showMenu && <MenuPanel />}

          {/* Navigation - Modificar los onClick para usar la nueva función */}
          <div className="flex gap-2 mb-4 bg-gray-700 p-2 rounded">
            <button
              onClick={() => handleTabClick("system")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "system" ? "bg-gray-600" : ""
              }`}
            >
              <PcCase className="w-6 h-6" />
              <span>System</span>
            </button>
            
            <button
              onClick={() => handleTabClick("peripherals")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "peripherals" ? "bg-gray-600" : ""
              }`}
            >
              <Monitor className="w-6 h-6" />
              <span>Peripherals</span>
            </button>
            <button
              onClick={() => handleTabClick("os")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "os" ? "bg-gray-600" : ""
              }`}
            >
              <AppWindow className="w-6 h-6" />
              <span>&nbsp;&nbsp;&nbsp;OS&nbsp;&nbsp;&nbsp;</span>
            </button>
            {/*<button
              onClick={() => handleTabClick("performance")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "performance" ? "bg-gray-600" : ""
              }`}
            >
              <Activity className="w-6 h-6" />
              <span>Performance</span>
            </button> */}
            {/*<button
              onClick={() => handleTabClick("processes")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "processes" ? "bg-gray-600" : ""
              }`}
            >
              <Cpu className="w-6 h-6" />
              <span>Processes</span>
            </button> */}
            {/*<button
              onClick={() => handleTabClick("users")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "users" ? "bg-gray-600" : ""
              }`}
            >
              <Users className="w-6 h-6" />
              <span>Users</span>
            </button> */}
            {/* <button
              onClick={() => handleTabClick("services")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "services" ? "bg-gray-600" : ""
              }`}
            >
              <Settings className="w-6 h-6" />
              <span>Services</span>
            </button> */}
            <button
              onClick={() => handleTabClick("vitals")}
              className={`flex flex-col items-center p-2 rounded ${
                activeTab === "vitals" ? "bg-gray-600" : ""
              }`}
            >
              <Activity className="w-6 h-6" />
              <span>Vitals</span>
            </button>
          </div>

          {/* Main Content Area with Left Sidebar */}
          <div className="flex gap-4">
            {/* Left Sidebar - Solo mostrar si activeTab es 'system' */}
            {activeTab === "system" && (
              <div
                className="w-48 bg-gray-700 rounded flex-shrink-0"
                role="navigation"
                aria-label="System navigation"
              >
                <MenuItem
                  text="Summary"
                  selected={selectedMenuItem === "summary"}
                  onClick={() => handleMenuItemClick("summary")}
                />
                <MenuItem
                  text="CPU"
                  selected={selectedMenuItem === "cpu"}
                  onClick={() => handleMenuItemClick("cpu")}
                />
                <MenuItem
                  text="Memory"
                  selected={selectedMenuItem === "memory"}
                  onClick={() => handleMenuItemClick("memory")}
                />
                <MenuItem
                  text="GPU"
                  selected={selectedMenuItem === "gpu"}
                  onClick={() => handleMenuItemClick("gpu")}
                />
                <MenuItem
                  text="Storage"
                  selected={selectedMenuItem === "disk"}
                  onClick={() => handleMenuItemClick("disk")}
                />
                <MenuItem
                  text="Network"
                  selected={selectedMenuItem === "network"}
                  onClick={() => handleMenuItemClick("network")}
                />
                {/* 
                <MenuItem 
                  text="Sensors"
                  selected={selectedMenuItem === 'sensors'}
                  onClick={() => handleMenuItemClick('sensors')}
                />
                */}
              </div>
            )}

            {/* Main Content - Ajustar el ancho cuando no hay sidebar */}
            <div className={`${activeTab === "system" ? "flex-1" : "w-full"}`}>
              <TabContent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemMonitor;
